<template>
    <div>
        <div v-if="fetchingFile">
            <v-container>
                <v-row>
                    <v-col cols="6">
                        <v-skeleton-loader type="article@2"></v-skeleton-loader>
                        <v-skeleton-loader type="paragraph" class="ml-4"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6">
                        <v-skeleton-loader type="article@2"></v-skeleton-loader>
                        <v-skeleton-loader type="paragraph" class="ml-4"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <editor
            v-else
            @change="setMarkdown"
            @load="loaded"
            class="markdown-file-editor"
            :options="defaultOptions"
            :height="editorHeight"
            ref="toastuiEditor"
            :initialEditType="editorMode"
            previewStyle="vertical" />
    </div>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/vue-editor'
import { toastEditor } from '@/mixins/toastEditor'

export default {
    name: 'MarkdownEditor',
    mixins: [toastEditor],
    components: {
        Editor
    },
    props: {
        placeholderText: String,
        editorHeight: { type: String, default: '500px' },
        editorMode: { type: String, default: 'wysiwyg' }
    },
    methods: {
        setMarkdown() {
            const markdown = this.$refs.toastuiEditor.invoke('getMarkdown')
            this.$emit('newMarkdownContent', { value: markdown })
        }
    },
    watch: {
        editorMode: {
            handler: function (mode) {
                if (mode === 'markdown') {
                    this.defaultOptions.hideModeSwitch = true
                }
            },
            immediate: true
        }
    }
}
</script>
